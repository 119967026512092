.recaptcha-modal {

    > .modal-content {

        > .modal-header {

            margin-bottom: 0;
        }

        > .modal-body {

            > #recaptcha {

                @include dimension(mobile-md) {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                }
                
                > div {
                    margin-bottom: 0;
                }
            }
        }

        > .modal-footer {

            > input[type=submit] {
                width: 156px;
            }
        }
    }
}

#order-form-recaptcha-modal {

}

#contact-form-recaptcha-modal {

}

#loading-modal {

    > .modal-content {

        > .modal-header {
            
            > .modal-title {

                > div {
                    display: flex;
                    flex-direction: row;
                    gap: $gap-8;

                    > .spinner-border.text-success {
                        align-self: center;
                    }

                    > p {
                        font-size: $font-size;

                        @include dimension(tablet) {
                            font-size: $font-size-tablet;
                        }
                    }
                }
            }
        }   
    }
}

#order-modal {

    > .modal-content {

        > .modal-body {

            > .image-text-inline {
                justify-content: center;

                > img {
                    width: 256px;
                }

                > .text {
                    text-align: center;

                    > h2 {
                        margin-left: 0;
                        margin-right: 0;
                    }
                }
            }

            > h3 {
                text-align: center;
            }

            > #order-form {
                display: flex;
                flex-direction: column;
            }
        }

        > .modal-footer {

            > input[type=submit] {
                width: 100%;

                @include dimension(tablet) {
                    width: $button-width;
                }
            }

            > p {
                margin: 0;
            }
        }
    }
}